import isset from "locutus/php/var/isset";
import is_array from "locutus/php/var/is_array";

export const commonHelper = {
    parseUserInfo,
    getDomain,
    getPath,
    getDomainLink,
    isValidHttpUrl,
    copyToClipboard,
    getShortName,
    getGeneratedColor,
    documentTitle,
};

/**
 *
 * @param task
 * @returns {*[]}
 */
function parseUserInfo(task) {
    let userInfo = [];
    let browserInfo;
    let warningsCount = 0;
    let errorsCount = 0;

    if (isset(task.console) && is_array(task.console)) {
        task.console.forEach(item => {
            if (item.log.type === 'Warn') {
                warningsCount++;
            } else if (item.log.type === 'Error') {
                errorsCount++;
            }
        })
    }

    browserInfo = isset(task.browser_info) ? task.browser_info : null;

    if (isset(browserInfo.browser && isset(browserInfo.browserVersion))) {
        userInfo.push(
            {
                slug: "user_browser",
                name: browserInfo.browser,
                version: browserInfo.browserVersion,
            }
        );
    }
    if (isset(browserInfo.os)) {
        userInfo.push(
            {
                slug: "user_os",
                name: browserInfo.os,
            });
    }
    if (isset(browserInfo.screenWidth) && isset(browserInfo.screenHeight) && isset(browserInfo.device)) {
        userInfo.push(
            {
                slug: "user_resolution",
                resolution: `${browserInfo.screenWidth}x${browserInfo.screenHeight}`,
                type: browserInfo.device.toLowerCase(),
            }
        )
    }
    userInfo.push(
        {
            slug: "user_warnings",
            count: warningsCount,
        }
    );
    userInfo.push(
        {
            slug: "user_errors",
            count: errorsCount,
        }
    );
    if (isset(browserInfo.ip) && isset(browserInfo.country)) {
        userInfo.push(
            {
                slug: "user_ip",
                ip: browserInfo.ip,
                country: browserInfo.country,
            }
        );
    }
    if (isset(browserInfo.enlargePercentage)) {
        userInfo.push(
            {
                slug: "user_scale",
                scale: browserInfo.enlargePercentage,
            }
        );
    }

    return userInfo;
}

/**
 *
 * @param link
 * @returns {string}
 */
function getDomain(link) {
    let url = new URL(link);
    return url.host;
}

/**
 *
 * @param link
 * @returns {string}
 */
function getPath(link) {
    let url = new URL(link);
    return url.pathname + url.search;
}

/**
 *
 * @param link
 * @returns {string}
 */
function getDomainLink(link) {
    let url = new URL(link);
    return `${url.protocol}//${url.host}`;
}

/**
 *
 * @param string
 * @returns {boolean}
 */
function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

function copyToClipboard(e, copy_data, replacement = '<i class="icon icon--ok"></i>') {
    let elem = e.target,
        tag  = elem.parentNode.tagName.toUpperCase();
    // TODO optimize this
    if (tag === 'BUTTON') {
        elem = elem.parentNode;
    }

    let html = elem.innerHTML;
    let el = document.createElement('textarea');
        el.value = copy_data;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';

    document.body.appendChild(el);
    const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }

    if( replacement ) {
        elem.innerHTML = replacement;
    }
    setTimeout(function () {
        elem.innerHTML = html;
    }, 1000);
}

function getShortName( str ) {
    const wordArray = str.split(' ').slice(0, 2);
    if (wordArray.length >= 2) {
        return wordArray.reduce((accumulator, currentValue) => {
            return `${accumulator}${currentValue[0].charAt(0)}`.toUpperCase();
        }, '');
    }
    return wordArray[0].charAt(0).toUpperCase();
}

/**
 * Generate color from string
 * @param str
 * @returns {string}
 */
function getGeneratedColor( str ) {
    let hash = 0,
        color = '#',
        value,
        strLength;

    if( !str ) {
        return color + '333333';
    }

    strLength = str.length;
    for (let i = 0; i < strLength; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    for (let i = 0; i < 3; i++) {
        value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
}

/**
 * Set title tag
 * @param title
 */
function documentTitle( title, postfix ) {
    document.title = title ? title + ( postfix ? ` | ${postfix}` : '' ) : 'QA Tool';
}